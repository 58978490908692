/* --------接口的地址-------------------------- */

/* --------左侧菜单是否走权限------------------- */
let authFlag = true
/* ------------参数是否加密----------------------------- */
let encodeFlag = false
/* ------------授权服务器的地址----------------------------- */


// erp正式
let baseUrl = 'https://erp.user.api.himihome.com'
let authUrl = 'https://erp.auth.himihome.com'

// gateway连接
// let baseUrl = 'https://saas.gateway.himihome.com/user'
// let authUrl = 'https://saas.auth.himihome.com'



export {
    baseUrl,
    authFlag,
    encodeFlag,
    authUrl

}
